<template>
  <v-main app>
    <!-- Provides the application the proper gutter -->

    <!-- fill-height class add for center -->

    <v-container
      class="horizontal-container rounded-xl relative"
      :class="{ 'grey lighten-5': !$vuetify.theme.dark }"
      style="margin-top: 130px !important"
    >
      <v-skeleton-loader
        v-if="getThemeMode.isLoading"
        :loading="getThemeMode.isLoading"
        width="100%"
        color="red"
        type="list-item-two-line, divider, image@3, card-heading"
      ></v-skeleton-loader>
      <template v-else>
        <top-nav-menu class="mb-4" />

        <v-expand-transition mode="out-in">
          <router-view />
        </v-expand-transition>
      </template>
    </v-container>

    <div class="flex-grow-1 mt-4" />
    <!-- <app-footer /> -->
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    // AppFooter: () => import('./Footer'),
    TopNavMenu: () => import('./TopNavMenu')
  },

  computed: {
    ...mapGetters(['getThemeMode'])
  }
};
</script>

<style lang="scss">
.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.horizontal-container {
  z-index: 1;
}
</style>
